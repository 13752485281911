import { Info } from "@styled-icons/octicons";
import { Modal } from "@components/Modal";
import { ProgressBar } from "@components/ProgressBar";

import { SplitLine } from "./styled";

interface SplitModalProps {
  isOpen: boolean;
  onClose: () => void;
  splitData: {
    collector: number;
    artist: number;
    project: number;
    community: number;
  };
  artistName: string;
  communityName: string;
}

const SplitModal = ({
  isOpen,
  onClose,
  splitData,
  artistName,
  communityName,
}: SplitModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Split Info">
      <p>
        <Info size="16" />
        &nbsp; all proceeds are distributed onchain at mint
      </p>
      <SplitLine>
        <p>Collectr</p>
        <p>{`${splitData.collector}%`}</p>
      </SplitLine>
      <ProgressBar progress={splitData.collector} />
      <SplitLine>
        <p>{`Artist: ${artistName}`}</p>
        <p>{`${splitData.artist}%`}</p>
      </SplitLine>
      <ProgressBar progress={splitData.artist} />
      <SplitLine>
        <p>{`Featured Project: ${communityName}`}</p>
        <p>{`${splitData.project}%`}</p>
      </SplitLine>
      <ProgressBar progress={splitData.project} />
      <SplitLine>
        <p>Featured Community Members</p>
        <p>{`${splitData.community}%`}</p>
      </SplitLine>
      <ProgressBar progress={splitData.community} />
    </Modal>
  );
};

export default SplitModal;
