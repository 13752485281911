import { getDefaultConfig } from "@rainbow-me/rainbowkit";
import { http } from "wagmi";
// import { base } from "wagmi/chains";

import {
  createClient,
  convertViemChainToRelayChain,
  TESTNET_RELAY_API,
  LogLevel,
  MAINNET_RELAY_API,
} from "@reservoir0x/relay-sdk";
import { arbitrum, arbitrumSepolia, base, baseSepolia, blast, mainnet, optimism, optimismSepolia, sepolia, zora, zoraSepolia } from "viem/chains";
import { IS_MAINNET } from "./utils/constants";

declare module "wagmi" {
  interface Register {
    config: typeof wagmiConfig;
  }
}

export const relayConfig = createClient({
  baseApiUrl: IS_MAINNET ? MAINNET_RELAY_API : TESTNET_RELAY_API,
  source: "https://collectr.uwulabs.com",
  chains: IS_MAINNET ? [
    convertViemChainToRelayChain(base),
    convertViemChainToRelayChain(mainnet),
    convertViemChainToRelayChain(arbitrum),
    convertViemChainToRelayChain(zora),
    convertViemChainToRelayChain(optimism),
    convertViemChainToRelayChain(blast),
  ] : [
    convertViemChainToRelayChain(baseSepolia),
    convertViemChainToRelayChain(sepolia),
    convertViemChainToRelayChain(arbitrumSepolia),
    convertViemChainToRelayChain(optimismSepolia),
    convertViemChainToRelayChain(zoraSepolia),
  ],
  logLevel: LogLevel.Info,
});

export const wagmiConfig = getDefaultConfig({
  appName: `${process.env.REACT_APP_WALLETCONNECT_APPNAME}`,
  projectId: `${process.env.REACT_APP_WALLETCONNECT_PROJECTID}`,
  chains: IS_MAINNET ? [base, mainnet, arbitrum, optimism, zora, blast] 
  : [baseSepolia, sepolia, arbitrumSepolia, optimismSepolia, zoraSepolia],
  transports: IS_MAINNET ? {
    [base.id]: http(
      `https://base-mainnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMYKEY_MAINNET}`
    ),
    [mainnet.id]: http(
      `https://eth-mainnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMYKEY_MAINNET}`
    ),
    [arbitrum.id]: http(
      `https://arb-mainnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMYKEY_MAINNET}`
    ),
    [optimism.id]: http(
      `https://opt-mainnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMYKEY_MAINNET}`
    ),
    [zora.id]: http(
      `https://zora-mainnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMYKEY_MAINNET}`
    ),
    [blast.id]: http(
      `https://blast-mainnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMYKEY_MAINNET}`
    ),
  } : {
    [baseSepolia.id]: http(
      `https://base-sepolia.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMYKEY_SEPOLIA}`
    ),
    [sepolia.id]: http(
      `https://eth-sepolia.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMYKEY_SEPOLIA}`
    ),
    [arbitrumSepolia.id]: http(
      `https://arb-sepolia.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMYKEY_SEPOLIA}`
    ),
    [optimismSepolia.id]: http(
      `https://opt-sepolia.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMYKEY_SEPOLIA}`
    ),
    [zoraSepolia.id]: http(
      `https://zora-sepolia.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMYKEY_SEPOLIA}`
    ),
  },
});
