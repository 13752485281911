import styled from "styled-components";

export const ProgressBarWrapper = styled.div`
  position: relative;
  height: ${({ theme }) => theme.fontSizes.normal};
  background-color: ${({ theme }) => theme.colors.grey40};
  border-radius: 50px;
  overflow: hidden;
`;

export const ProgressBarFill = styled.div<{ width: number }>`
  position: absolute;
  left: 0;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
  opacity: 1;
  width: ${(props) => `${props.width}%`};
  border-radius: 50px;
`;

export const ProgressBarText = styled.div`
  color: ${({ theme }) => theme.colors.bgColor};
  font-size: ${({ theme }) => theme.fontSizes.xxsmall};
  line-height: ${({ theme }) => theme.fontSizes.xlarge};
  text-align: center;

  strong {
    font-size: ${({ theme }) => theme.fontSizes.xxsmall};
    line-height: ${({ theme }) => theme.fontSizes.xlarge};
    font-weight: 1000;
  }
`;
