import ReactDOM from "react-dom";

import { X } from "@styled-icons/octicons";

import { ModalProps } from "./types";
import {
  ModalOverlay,
  ModalContent,
  ModalTitle,
  CloseButton,
  ContentWrapper,
} from "./styled";

const Modal = ({
  isOpen,
  onClose,
  children,
  title,
  overflowY = true,
}: ModalProps) => {
  const handleClickOutside = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.target as HTMLElement;
    if (!target.closest("#modal-content")) {
      onClose();
    }
  };

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <ModalOverlay onClick={handleClickOutside}>
      <ModalContent id="modal-content">
        {title && <ModalTitle>{title}</ModalTitle>}
        <CloseButton onClick={onClose}>
          <X />
        </CloseButton>
        <ContentWrapper overflowY={overflowY}>{children}</ContentWrapper>
      </ModalContent>
    </ModalOverlay>,
    document.getElementById("root") as HTMLElement
  );
};

export default Modal;
