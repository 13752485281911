import styled from "styled-components";
import { ContainerContent } from "@components/Layout";
import { IconLink } from "@src/components/Links";

export const ModalContainerContent = styled(ContainerContent)`
  max-width: 480px;

  p {
    max-width: 100%;
    overflow-wrap: break-word;
    margin-bottom: 0px;
  }
`;

export const PoweredByText = styled.a`
  display: block;
  text-align: center;
  margin-bottom: 16px;
  margin-top: 8px;
  margin-left: auto;
  margin-right: auto;
  text-decoration: none;
  width: fit-content;
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
`;

export const ModalHeader = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.normal};
  text-align: center;
  margin-bottom: 0px;
`;

export const ModalSubline = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  color: ${({ theme }) => theme.colors.royalBlue};
  text-align: center;
  margin-bottom: 32px;
`;

export const ModalEmailHeader = styled.p`
  text-align: center;
  margin-bottom: 0px;
  color: ${({ theme }) => theme.colors.grey90};
`;

export const SplitLine = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;

  p {
    font-size: ${({ theme }) => theme.fontSizes.xsmall};
    margin-bottom: 0;
  }

  p:first-child {
    flex-grow: 1;
    text-align: left;
  }

  p:last-child {
    flex-grow: 0;
    text-align: right;
  }
`;

export const Spinner = styled.div`
  border: 10px solid rgba(0, 0, 0, 0.1);
  border-left-color: #000;
  border-radius: 50%;
  margin: 48px 0 48px 0;
  width: 108px;
  height: 108px;
  animation: spin 2s linear infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

export const EditionIconLink = styled(IconLink)`
  svg {
    fill: ${({ theme }) => theme.colors.primary};
  }

  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.lessRoyalBlue};
    }
  }
`;
