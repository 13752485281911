import { ProgressBarProps } from "./types";
import { ProgressBarWrapper, ProgressBarFill, ProgressBarText } from "./styled";

const ProgressBar = ({ progress, children }: ProgressBarProps) => (
  <ProgressBarWrapper>
    <ProgressBarFill width={progress} />
    <ProgressBarText>{children}</ProgressBarText>
  </ProgressBarWrapper>
);

export default ProgressBar;
