import styled from "styled-components";
import { Modal } from "@components/Modal";
import {
  VerticalStack,
  HorizontalStack,
  ContainerContent,
} from "@components/Layout";
import { Button } from "@components/Buttons";
import { analytics, logEvent } from "../../../firebase";

interface BenefitsModalProps {
  tag: string;
  isOpen: boolean;
  onClose: () => void;
  benefitsLink: string;
}

const WallpaperBlurb = styled.div`
  font-weight: 700;
  padding: 12px 16px;
  border: 1px solid #dadada;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 16px;
`;

const DownloadButton = styled(Button)`
  width: 100%;
`;

const BenefitsModal = ({
  isOpen,
  onClose,
  benefitsLink,
  tag,
}: BenefitsModalProps) => {
  const handleDownload = () => {
    if (analytics) {
      logEvent(analytics, "wallpapers_downloaded", { edition: tag });
    }
    const link = document.createElement("a");
    link.href = benefitsLink;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Exclusive Wallpapers">
      <ContainerContent>
        <HorizontalStack>
          <VerticalStack>
            <WallpaperBlurb>
              Desktop (Standard 16:9 + Ultrawide 21:9)
            </WallpaperBlurb>
            <WallpaperBlurb>Mobile (Standard + Tall)</WallpaperBlurb>
            <WallpaperBlurb>Smart Watch (Standard)</WallpaperBlurb>
            <DownloadButton onClick={handleDownload}>
              download all
            </DownloadButton>
          </VerticalStack>
        </HorizontalStack>
      </ContainerContent>
    </Modal>
  );
};

export default BenefitsModal;
