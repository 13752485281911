import { Modal } from "@components/Modal";
import {
  VerticalStack,
  HorizontalStack,
  ContainerContent,
} from "@components/Layout";
import { SubscribeForm } from "@components/SubscribeForm";
import { Spinner, ModalContainerContent, ModalHeader, ModalSubline, ModalEmailHeader } from "./styled";

interface TransactionModalProps {
  isOpen: boolean;
  onClose: () => void;
  status: "pending" | "success" | "error";
  message: string;
  title?: string;
}

const TransactionModal = ({
  isOpen,
  onClose,
  status,
  message,
  title,
}: TransactionModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title}>
      {status === "pending" && (
        <ModalContainerContent>
          <VerticalStack>
            <Spinner />
            <p>{message}</p>
          </VerticalStack>
        </ModalContainerContent>
      )}
      {status === "success" && (
        <ModalContainerContent>
          <ContainerContent>
            <HorizontalStack>
              <VerticalStack>
                <ModalHeader>{message}</ModalHeader>
                <ModalSubline>View your wallpaper benefits on the edition page.</ModalSubline>
                <ModalEmailHeader>Get reminded next month to keep building your collection!</ModalEmailHeader>
                <SubscribeForm />
              </VerticalStack>
            </HorizontalStack>
          </ContainerContent>
        </ModalContainerContent>
      )}
      {status === "error" && (
        <ModalContainerContent>
          <VerticalStack>
            <p>{message}</p>
          </VerticalStack>
        </ModalContainerContent>
      )}
    </Modal>
  );
};

export default TransactionModal;
