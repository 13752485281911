import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import styled from "styled-components";

import { formatTimeLeft } from "@utils/utils";
import { useLatestEdition } from "@context/EditionsProvider";
import {
  Container,
  ContainerContent,
  EqualHStack,
  VerticalStack,
  Spacer,
} from "@components/Layout";
import { Button } from "@components/Buttons";
import { EditionCard } from "@components/Cards";
import { Footer } from "@components/Footer";
import { SubscribeModal } from "@components/SubscribeForm";

import { editions } from "@apis/editions";
import useMintStatuses from "@hooks/useMintStatuses";

const GalleryContainer = styled(Container)`
  min-height: 95vh;
  opacity: 1;
  transition: opacity 0.5s linear;

  &.loading {
    opacity: 0;
  }
`;

const GalleryHStack = styled(EqualHStack)`
  justify-content: flex-start;

  @media ${({ theme }) => theme.breakpoints.md} {
    > * {
      width: ${({ theme, columns = 3, gapSize = theme.spacing.x2 }) => {
        const halfColumns = Math.ceil(columns / 2);
        const numGaps = halfColumns - 1;
        const contentWidth = `100% - (${gapSize} * ${numGaps})`;
        return `calc((${contentWidth}) / ${halfColumns})`;
      }};
    }
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    > * {
      width: ${({ theme, columns = 3, gapSize = theme.spacing.x2 }) => {
        const halfColumns = 3;
        const numGaps = halfColumns - 1;
        const contentWidth = `100% - (${gapSize} * ${numGaps})`;
        return `calc((${contentWidth}) / ${halfColumns})`;
      }};
    }
  }

  @media ${({ theme }) => theme.breakpoints.xl} {
    > * {
      width: ${({ theme, columns = 3, gapSize = theme.spacing.x2 }) => {
        const numGaps = columns - 1;
        const contentWidth = `100% - (${gapSize} * ${numGaps})`;
        return `calc((${contentWidth}) / ${columns})`;
      }};
    }
  }
`;

const NextEditionPlaceholder = styled.div`
  position: relative;
  display: flex;
  height: 380px;
  border-radius: 24px;
  border: 4px solid #fff;
  align-items: center;
  justify-content: center;
  padding: 36px;
  overflow: hidden;

  h1 {
    text-align: center;
    font-size: 40px;
    font-weight: 900;
    margin-bottom: 16px;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    height: 544px;
  }
`;

const NextEditionBg = styled.div`
  position: absolute;
  height: 544px;
  width: 100%;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 0.1) 100%
    ),
    url("images/teaser-placeholder.webp") lightgray 50% / cover no-repeat;
  filter: grayscale(10%);
  z-index: -1;
`;

const Gallery = () => {
  const [subscribeModalOpen, setSubscribeModalOpen] = useState(false);
  const latestEdition = useLatestEdition();

  const { data: featuredEditions = [], isLoading: loadingEditions } = useQuery({
    queryKey: ["editions"],
    queryFn: editions.index,
  });

  const mintStatuses = useMintStatuses(featuredEditions);

  const handleOpenSubscribeModal = () => {
    setSubscribeModalOpen(true);
  };

  const handleCloseSubscribeModal = () => {
    setSubscribeModalOpen(false);
  };

  const reversedEditions = [...featuredEditions].reverse();

  return (
    <>
      <GalleryContainer className={loadingEditions ? "loading" : ""}>
        <ContainerContent>
          <VerticalStack>
            <Spacer />
            {loadingEditions ? (
              "Loading..."
            ) : (
              <>
                <GalleryHStack columns={4} gapSize="32px" $isWrap={true}>
                  <NextEditionPlaceholder>
                    <NextEditionBg />
                    <VerticalStack>
                      {latestEdition ? (
                        <h1>
                          Next Edition in{" "}
                          {formatTimeLeft(latestEdition.endTime)}
                        </h1>
                      ) : (
                        ""
                      )}
                      <Button onClick={handleOpenSubscribeModal}>
                        stay notified
                      </Button>
                    </VerticalStack>
                  </NextEditionPlaceholder>
                  {reversedEditions.map((featuredEdition, index) => (
                    <>
                      <EditionCard
                        key={`${featuredEdition.year}-${featuredEdition.month}`}
                        year={featuredEdition.year}
                        month={featuredEdition.month}
                        imgurl={featuredEdition.projectInfo.imageURL}
                        creatorName={featuredEdition.artistInfo.name}
                        creatorFlag={featuredEdition.artistInfo.flag}
                        title={featuredEdition.editionPage.name}
                        price={featuredEdition.editionPage.price}
                        isHighlighted={
                          !!(
                            latestEdition &&
                            latestEdition.year === featuredEdition.year &&
                            latestEdition.month === featuredEdition.month
                          )
                        }
                        hasMinted={mintStatuses[featuredEdition.instanceId]}
                      />
                    </>
                  ))}
                </GalleryHStack>
              </>
            )}
            <SubscribeModal
              isOpen={subscribeModalOpen}
              onClose={handleCloseSubscribeModal}
            />
          </VerticalStack>
          <Spacer />
        </ContainerContent>
      </GalleryContainer>
      <Footer />
    </>
  );
};

export default Gallery;
