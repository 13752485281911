import { useState } from "react";
import styled, { css } from "styled-components";
import axios from "axios";
import { toast } from "react-toastify";

import { ChevronRight } from "@styled-icons/octicons";
import { Button } from "@components/Buttons";
import { VerticalStack } from "@components/Layout";
import { analytics, logEvent } from "../../firebase";

const InputContainer = styled(VerticalStack)<{ compact?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;

  ${({ compact }) =>
    compact &&
    css`
      flex-direction: row;
      padding: 0;
    `}

  @media ${({ theme }) => theme.breakpoints.md} {
    max-width: 800px;
    margin: 0 auto;
  }
`;

const Input = styled.input<{ compact?: boolean }>`
  flex-grow: 1;
  padding: 16px 32px;
  font-size: 16px;
  border: 1px solid ${({ theme }) => theme.colors.grey40};
  border-radius: 10px;
  outline: none;
  min-width: 0;
  width: 100%;
  max-width: 700px;
  margin-bottom: 16px;

  ${({ compact }) =>
    compact &&
    css`
      min-width: 275px;
      font-size: 16px;
      padding: 16px 32px;
      border: none;
      border-radius: 16px 0 0 16px;
      margin-bottom: 0;

      @media ${({ theme }) => theme.breakpoints.md} {
        font-size: 18px;
        min-width: 300px;
      }
    `}

  @media ${({ theme }) => theme.breakpoints.md} {
    font-size: 18px;
  }
`;

const SubscribeButton = styled(Button)<{
  compact?: boolean;
  disabled?: boolean;
}>`
  padding-left: 48px;
  padding-right: 48px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ compact }) =>
    compact &&
    css`
      padding: 13px 15px 15px 15px;
      border: none;
      border-radius: 0 16px 16px 0;
      background-color: #00b4ff;
      color: white;
      cursor: pointer;

      &:hover {
        background-color: #008ecf;
      }

      svg {
        padding: 0;
        margin: 0;
      }
    `}

  ${({ disabled }) =>
    disabled
      ? css`
          background-color: #00b4ff;
          cursor: not-allowed;
          opacity: 0.6;

          &:hover {
            background-color: #00b4ff;
            color: #fff;

            svg {
              fill: #fff;
            }
          }
        `
      : css`
          &:hover {
            background-color: #008ecf;
            color: #fff;

            svg {
              fill: #fff;
            }
          }
        `}
`;

const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #fff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

interface SubscribeFormProps {
  compact?: boolean;
}

const SubscribeForm = ({ compact }: SubscribeFormProps) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const isEmailValid = emailRegex.test(email);

  const handleSubscribe = async () => {
    if (!isEmailValid) {
      toast.error("Please enter a valid email address.", {
        position: "bottom-right",
        className: "custom-toast",
      });
      return;
    }

    setLoading(true);

    const headers = {
      "Content-Type": "application/json",
    };

    const data = {
      email,
      reactivate_existing: false,
      send_welcome_email: true,
      utm_source: "collec.tr",
      utm_campaign: "collectr-notifications",
      utm_medium: "web",
      referring_site: "collec.tr",
      custom_fields: [],
    };

    try {
      const response = await axios.post("/subscribe", data, { headers });
      if (response.status === 201) {
        toast.success("Subscribed successfully!", {
          position: "bottom-right",
          className: "custom-toast",
        });
        if (analytics) {
          logEvent(analytics, "subscription_success", { email });
        }
      } else {
        toast.error("Failed to subscribe.", {
          position: "bottom-right",
          className: "custom-toast",
        });
        if (analytics) {
          logEvent(analytics, "subscription_failure", { email });
        }
      }
    } catch (error) {
      let errorMessage = "An unknown error occurred.";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      toast.error("Error subscribing. Please try again.", {
        position: "bottom-right",
        className: "custom-toast",
      });
      if (analytics) {
        logEvent(analytics, "subscription_error", {
          email,
          error: errorMessage,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && isEmailValid) {
      handleSubscribe();
    }
  };

  return (
    <>
      <InputContainer compact={compact}>
        <Input
          type="email"
          autoComplete="email"
          placeholder="enter your email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyPress={handleKeyPress}
          compact={compact}
        />
        <SubscribeButton
          onClick={handleSubscribe}
          compact={compact}
          disabled={loading || !isEmailValid}
        >
          {loading ? (
            <Spinner />
          ) : compact ? (
            <ChevronRight size="24" />
          ) : (
            "subscribe"
          )}
        </SubscribeButton>
      </InputContainer>
    </>
  );
};

export default SubscribeForm;
