import { useState, useEffect } from "react";
import styled from "styled-components";
import Select, {
  components,
  OptionProps,
  SingleValueProps,
  OnChangeValue,
} from "react-select";
import {
  base,
  mainnet,
  arbitrum,
  optimism,
  zora,
  blast,
  baseSepolia,
  sepolia,
  arbitrumSepolia,
  optimismSepolia,
  zoraSepolia,
} from "viem/chains";
import { useAccount, useSwitchChain, useBalance } from "wagmi";
import { Modal } from "@components/Modal";
import {
  VerticalStack,
  HorizontalStack,
  ContainerContent,
} from "@components/Layout";
import { Button } from "@components/Buttons";
import { IS_MAINNET } from "@utils/constants";
import { ETH, Base, OP, Arbitrum, Zora, Blast } from "@assets/NetworkLogos";
import { ModalContainerContent, PoweredByText } from "./styled";
import { parseEther } from "viem";

interface PaymentMethodModalProps {
  isOpen: boolean;
  onClose: () => void;
  handlePayment: () => void;
}

const StyledSelect = styled(Select)<any>`
  width: 100%;
  margin-bottom: 16px;
`;

const StyledSelectHStack = styled(HorizontalStack)`
  svg {
    margin-top: 6px;
  }
  img {
    margin-top: 6px;
  }
`;

const Balance = styled.div`
  font-size: 0.75em;
`;

const options = IS_MAINNET
  ? [base, mainnet, arbitrum, optimism, zora, blast]
  : [baseSepolia, sepolia, arbitrumSepolia, optimismSepolia, zoraSepolia];

const minimum = IS_MAINNET ? parseEther("0.01") : parseEther("0.0000001");

const chainLogoMap: { [key: number]: React.ComponentType<any> } = {
  [mainnet.id]: ETH,
  [base.id]: Base,
  [arbitrum.id]: Arbitrum,
  [optimism.id]: OP,
  [zora.id]: Zora,
  [blast.id]: Blast,
  [sepolia.id]: ETH,
  [baseSepolia.id]: Base,
  [arbitrumSepolia.id]: Arbitrum,
  [optimismSepolia.id]: OP,
  [zoraSepolia.id]: Zora,
};

const Option = (props: OptionProps) => {
  const { address } = useAccount();
  const { data } = props;
  const Logo = chainLogoMap[data.id] || ETH;

  const { data: balanceData } = useBalance({
    address,
    chainId: data.id,
  });

  return (
    <components.Option {...props} isDisabled={balanceData?.value < minimum}>
      <StyledSelectHStack>
        <Logo />
        <div>
          <strong>{data.name}</strong>
          <Balance>
            Balance: {parseFloat(balanceData?.formatted || 0).toFixed(4)} ETH
          </Balance>
        </div>
      </StyledSelectHStack>
    </components.Option>
  );
};

const SingleValue = (props: SingleValueProps) => {
  const { address } = useAccount();
  const { data } = props;
  const Logo = chainLogoMap[data.id] || ETH;

  const { data: balanceData } = useBalance({
    address,
    chainId: data.id,
  });

  return (
    <components.SingleValue {...props}>
      <StyledSelectHStack>
        <Logo />
        <div>
          <strong>{data.name}</strong>
          <Balance>
            Balance: {parseFloat(balanceData?.formatted || 0).toFixed(4)} ETH
          </Balance>
        </div>
      </StyledSelectHStack>
    </components.SingleValue>
  );
};

const PaymentMethodModal = ({
  isOpen,
  onClose,
  handlePayment,
}: PaymentMethodModalProps) => {
  const { address, chain } = useAccount();
  const { chains, switchChain } = useSwitchChain();
  let defaultChain = chain || base;
  const [selectedChain, setSelectedChain] = useState(defaultChain);
  const [isConnectedToSelectedChain, setIsConnectedToSelectedChain] =
    useState(false);

  const { data: balanceData } = useBalance({
    address,
    chainId: selectedChain.id,
  });
  const [sufficientBalance, setSufficientBalance] = useState(
    balanceData?.value > minimum
  );

  useEffect(() => {
    if (chain && selectedChain) {
      if (balanceData?.value < minimum) {
        setSufficientBalance(false);
      } else {
        setSufficientBalance(true);
      }
      setIsConnectedToSelectedChain(chain.id === selectedChain.id);
    }
  }, [chain, selectedChain]);

  useEffect(() => {
    if (chain) {
      const connectedChain = options.find((option) => option.id === chain.id);
      if (connectedChain) {
        setSelectedChain(connectedChain);
      }
    }
  }, [chain]);

  const onChange = (value: OnChangeValue<OptionType, false>) => {
    setSelectedChain(value);
  };

  const handleButtonClick = () => {
    if (isConnectedToSelectedChain) {
      handlePayment(selectedChain.id);
    } else {
      switchChain?.({ chainId: selectedChain.id });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Choose payment method"
      overflowY={false}
    >
      <ContainerContent>
        <HorizontalStack>
          <VerticalStack>
            <ModalContainerContent>
              <p>No bridging needed, pay from anywhere and mint on Base</p>
              <PoweredByText
                href="https://www.relay.link/"
                target="_blank"
                rel="noreferrer"
              >
                powered by Relay
              </PoweredByText>
            </ModalContainerContent>

            <StyledSelect
              components={{ Option, SingleValue }}
              options={options}
              getOptionLabel={(option: OptionType) => option.name}
              getOptionValue={(option: OptionType) => option.id}
              isSearchable={false}
              onChange={onChange}
              defaultValue={selectedChain}
            />

            <Button isDisabled={!sufficientBalance} onClick={handleButtonClick}>
              {sufficientBalance
                ? isConnectedToSelectedChain
                  ? "collect"
                  : "switch network"
                : "not enough ETH"}
            </Button>
          </VerticalStack>
        </HorizontalStack>
      </ContainerContent>
    </Modal>
  );
};

export default PaymentMethodModal;
