import styled from "styled-components";

import { SVGButton } from "@components/Buttons";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  position: relative;
  max-height: 70%;
  max-width: 90vw;
  color: #000;
  background-color: white;
  padding: 0px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`;

export const ModalTitle = styled.h3`
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: 700;
  text-align: center;
  padding: 12px 8px 8px 8px;

  @media ${({ theme }) => theme.breakpoints.md} {
    padding: 16px;
  }
`;

interface ContentWrapperProps {
  overflowY?: boolean;
}

export const ContentWrapper = styled.div<ContentWrapperProps>`
  flex: 1;
  overflow-y: ${({ overflowY }) => (overflowY ? "auto" : "none")};
  padding: 0 24px 24px 24px;
`;

export const CloseButton = styled(SVGButton)`
  position: absolute;
  top: 2px;
  right: -8px;
  background-color: transparent;
  border: none;
  cursor: pointer;

  svg {
    fill: ${({ theme }) => theme.colors.grey60};
    height: 40px;
    width: 40px;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    position: absolute;
    top: 10px;
    right: 0px;

    svg {
      fill: ${({ theme }) => theme.colors.grey60};
      height: 36px;
      width: 36px;
    }
  }
`;
